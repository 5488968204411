import {
  Boutique,
  Buyer,
  Company,
  ProductPrice,
  QuantityWithOption,
  StoredObject,
} from "@/features/types";
import {
  LightLineSheet,
  LightLineSheetSet,
  PriceAdjustedBy,
} from "@/features/line-sheet-sets/line-sheet-set.types";

export interface OrderSheetProduct {
  id: number;
  firstOrderQuantityWithOptionList: QuantityWithOption[] | null;
  prevOrderQuantityWithOptionList: QuantityWithOption[] | null;
  latestOrderQuantityWithOptionList: QuantityWithOption[] | null;
  orderQuantityWithOptionList: QuantityWithOption[];
  confirmedQuantityWithOptionList: QuantityWithOption[] | null;
  supplyPrice: ProductPrice | null;
  priceAdjustedBy: PriceAdjustedBy;

  //이렇게 3개 추가하면 될듯?
  newStockWithOptionList: QuantityWithOption[] | null;
  confirmedPrice: ProductPrice | null;
  confirmedPriceAdjustedBy: PriceAdjustedBy | null;
}

export interface LightOrderSheetSetPair {
  lineSheetSet: LightLineSheetSet;
  orderSheetSet: LightOrderSheetSet;
}

export interface DenseOrderSheetSet
  extends Omit<LightOrderSheetSet, "lineSheetSet" | "orderSheets"> {
  lineSheetSet: LightLineSheetSet;
  orderSheets: DenseOrderSheet[];
}

export interface DenseOrderSheet
  extends Omit<LightOrderSheet, "lineSheet" | "orderSheetTabs"> {
  lineSheet: LightLineSheet;
  orderSheetTabs: DenseOrderSheetTab[];
}

export interface DenseOrderSheetTab extends LightOrderSheetTab {
  name: string;
}

export interface OrderSheetSet {
  id: number;
  lineSheetSet: {
    id: number;
  };
  status: OrderSheetStatus;
  createdByCompany: Buyer;
  updatedAt: Date;
  createdAt: Date;
  submittedAt: Date | null;
  orderSheets: OrderSheet[];
  conditions: Condition[];
  compressedExportedFile: StoredObject | null;
  isRead: boolean;
  orderedConditionType: ConditionType;
  orderedAmountWithoutCondition: number;
  orderedAmount: number;
  orderedQuantity: number;
  confirmedAmount: number;
  confirmedQuantity: number;
  boutiqueConfirmationMetadataList: BoutiqueConfirmationMetadata[] | null;
}

export interface LightOrderSheetSet extends Omit<OrderSheetSet, "orderSheets"> {
  orderSheets: LightOrderSheet[];
}

export interface OrderSheet {
  id: number;
  status: OrderSheetStatus;
  lineSheet: {
    id: number;
  };
  suffix: string;
  toBeSubmitted: Boutique | null;
  updatedAt: Date;
  createdAt: Date;
  orderSheetTabs: OrderSheetTab[];
  uploadedFile: StoredObject | null;
  exportedFile: StoredObject | null;
  sourceOrderSheet: {
    id: number;
  } | null;
  orderedConditionType: ConditionType;
  orderedAmountWithoutCondition: number;
  orderedAmount: number;
  orderedQuantity: number;
  confirmedAmount: number;
  confirmedQuantity: number;
}

export interface LightOrderSheet extends Omit<OrderSheet, "orderSheetTabs"> {
  orderSheetTabs: LightOrderSheetTab[];
}

export interface OrderSheetTab {
  index: number;
  products: OrderSheetProduct[];
}

export interface LightOrderSheetTab extends Omit<OrderSheetTab, "products"> {}

export type OrderSheetStatus =
  | "INITIAL_DRAFT"
  | "WORKING_DRAFT"
  | "ORDERED_TO_AGENCY"
  | "AGENCY_CONFIRMED"
  | "RESUBMIT_REQUIRED"
  | "RESUBMIT_COMPLETED"
  | "ORDERED_TO_BOUTIQUE"
  | "CANCELED"
  | "MODIFICATION_REQUIRED"
  | "MODIFICATION_COMPLETED"
  | "BOUTIQUE_CONFIRMED"
  | "COMPLETED";

export const OrderSheetStatuses: OrderSheetStatus[] = [
  "INITIAL_DRAFT",
  "WORKING_DRAFT",
  "ORDERED_TO_AGENCY",
  "AGENCY_CONFIRMED",
  "ORDERED_TO_BOUTIQUE",
  "CANCELED",
  "MODIFICATION_REQUIRED",
  "MODIFICATION_COMPLETED",
  "BOUTIQUE_CONFIRMED",
  "COMPLETED",
];

export function isOrderedToAgency(orderSheetOrSet: {
  status: OrderSheetStatus;
}) {
  const { status } = orderSheetOrSet;
  return (
    ["INITIAL_DRAFT", "WORKING_DRAFT", "RESUBMIT_REQUIRED"].indexOf(status) ===
    -1
  );
}

export interface FooStatistic {
  firstOrderedAmount: number | null;
  firstOrderedQuantity: number | null;
  firstOrderedAmountWithoutCondition: number | null;
  firstOrderedConditionType: ConditionType | null;

  totalOrderedAmount: number;
  totalOrderedQuantity: number;
  totalOrderedAmountWithoutCondition: number;
  orderedConditionType: ConditionType;

  totalConfirmedAmount: number | null;
  totalConfirmedQuantity: number | null;
  confirmedConditionType: ConditionType | null;
  totalConfirmedAmountWithoutCondition: number | null;
}

export type ConditionType = "MARKUP" | "DISCOUNT" | "MIX";

export function getConditionTypeSymbol(type: ConditionType | null) {
  switch (type) {
    case "MARKUP":
      return "C+";
    case "DISCOUNT":
      return "R-";
    case "MIX":
      return "R/C";
    default:
      return "";
  }
}

export const ConditionTypes: ConditionType[] = ["MARKUP", "DISCOUNT", "MIX"];

export interface Condition {
  category: string;
  brand: Pick<Company, "id" | "name">;
  type: ConditionType;
  value: number;
}

export interface BoutiqueConfirmationMetadata {
  toBeSubmitted: Pick<Company, "id" | "name">;
  conditions: Condition[];
  totalAmount: number;
  totalQuantity: number;
}

export const ConditionCategories = [
  "ALL",
  "ACC",
  "RTW",
  "SHOES",
  "BAGS",
  "MEN",
  "WOMEN",
  "KIDS",
];
